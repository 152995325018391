<template>
  <header>
    <p v-show="titleBefore" class="data-details__cat text-center">{{ titleBefore }}</p>
    <h1 class="data-details__title">{{ title }}</h1>
    <slot name="title-after">
      <p v-show="titleAfter" class="data-details__cat text-center mt-3">{{ titleAfter }}</p>
    </slot>

    <div class="image-wrapper" :class="{ 'image-wrapper--with-credit': !!imageCreditLine }">
      <div class="data-details__img mt-4" :class="[imageClass]" :style="`background-image:url(${image})`" />
      <p v-show="imageCreditLine" class="image-credit-line">{{ imageCreditLine }}</p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'EntityPageHeader',
  props: {
    image: {
      type: String,
      default: '',
    },
    imageClass: {
      type: String,
      default: '',
    },
    imageDetails: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    titleBefore: {
      type: String,
      default: '',
    },
    titleAfter: {
      type: String,
      default: '',
    },
  },
  computed: {
    imageCreditLine() {
      return this.imageDetails ? this.imageDetails.creditLine : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-details__title,
.image-credit-line {
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
}

.image-wrapper {
  &--with-credit {
    margin-bottom: 60px;

    .data-details__img {
      margin-bottom: 24px;
    }
  }

  .image-credit-line {
    font-style: italic;
    color: #a2a2a2;
  }
}
</style>
